import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import BlockPrincipal from "../components/block/Block_4";
import BlockSecondary from "../components/block/Block_1";
import BlockExtra from "../components/block/Block_3";
import Map from "../components/Contact/MapContent";

function City() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Service Area">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Service Area"
          Subheader="Service Area"
          bgimg={`${rpdata?.stock?.[4]}`}
        />

        <BlockPrincipal
            title={'Expert roofing service for homeowners across Pennsylvania'}
            text={rpdata?.dbAbout?.[1]?.text}
            image1={rpdata?.stock?.[11]}
            image2={rpdata?.stock?.[10]}
            image3={rpdata?.stock?.[12]}
            listsAbout
          />

        <BlockSecondary
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbAbout?.[3]?.text}
          image={rpdata?.gallery?.[1]}
          listsworks={true}
        />

        <BlockExtra
          title={rpdata?.dbSlogan?.[6]?.slogan}
          text={rpdata?.dbAbout?.[4]?.text}
          image={rpdata?.gallery?.[4]}
        />
      </div>

      <Map />
    </BaseLayout>
  );
}

export default City;
